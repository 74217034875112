import request from '@core/utils/RequestApi'
import qs from 'qs'
export function Get(data) {
  return request({
    url: '/Address/Get',
    method: 'get',
    params: data,
  })
}
export function GetByRestaurntCity(data) {
  return request({
    url: '/Address/GetByRestaurntCity',
    method: 'get',
    params: data,
  })
}
export function EditCustomerAddress(data) {
  return request({
    url: '/Address/EditCustomerAddress',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Create(data) {
  return request({
    url: '/Address/Create',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Edit(data) {
  return request({
    url: '/Address/Edit',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Delete(data) {
  return request({
    url: '/Address/Delete',
    method: 'post',
    data: qs.stringify(data),
  })
}
