<template>
  <v-select
    @change="Setmodel"
    v-model="value"
    :items="items"
    label="Bransh"
    item-text="Name"
    item-value="Id"
    hide-details
  ></v-select>
</template>

<script>
import { GetByRestaurantId } from '@core/api/Branch';
import { onMounted, ref } from '@vue/composition-api';

export default {
  model: {
    prop: 'BranshId',
    event: 'update:bransh-id',
  },
  props: {
    RestaurantId: {
      type: Number,
      require: true,
    },
  },
  watch: {
    RestaurantId(val) {
      this.GetBransh(val)
    },
  },
  setup(prop, { emit }) {
    const value = ref({
      Id: null,
      Name: 'All',
      label: 'All',
      Link: null,
      PhoneNumber: '4564564645',
      Status: 'active',
      Tax: 1,
      Type: null,
      value: null,
    })
    const GetBransh = val => {
      GetByRestaurantId({ id: prop.RestaurantId }).then(response => {
        items.value.push(value.value)
        items.value.push(...response)
      })
    }
    onMounted(() => {
      GetBransh(prop.RestaurantId)
    })
    const Setmodel = () => {
      emit('update:bransh-id', value.value)
    }
    const items = ref([])

    return { items, Setmodel, value, GetBransh }
  },
}
</script>
