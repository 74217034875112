<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="orderCustomerData.Name"
          label="Name"
          :rules="[required]"
          @change="val => $emit('update:is-add-active', val)"
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field disabled label="PhoneNumber" v-model="orderCustomerData.PhoneNumber"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-select
          @change="SetAddress($event)"
          :items="isEmptyAddress? em : orderCustomerData.CustomerAddresseses"
          :label="orderCustomerData.AddressName == '' || orderCustomerData.AddressName == null ? 'Select Address' : orderCustomerData.AddressName"
          item-text="Name"
          :return-object="true"
          eager
          :key="key"
          hide-details
        >
        <template v-slot:append-item >
          <v-list-item
          v-for="(item, i) in HomesArray"
        :key="i"
          @mousedown.prevent
          @click="toggle(item)"
          class=" mt-2 "
        >
        <v-list-item-avatar color="#eee">
          <v-icon large >
              {{mdiFoodApple}}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content class=" error--text">
            {{ item }}
<v-divider class="pb-2"></v-divider>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item
        @click="toggle(4)"
          ripple
          @mousedown.prevent
        >
          <v-list-item-content>
            <v-list-item >
          <v-list-item-avatar color="#eee ">
             <v-icon large>
              {{mdiFoodAppleOutline}}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title class="error--text"> Work </v-list-item-title>
          </v-list-item>
          </v-list-item-content>
        </v-list-item> -->
      </template>
      </v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field disabled label="Address Name" v-model="orderCustomerData.AddressName"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <vc-jordan-citiy-select @change="SelectCity" :City="orderCustomerData.City" v-model="orderCustomerData.City" />
      </v-col>
      <v-col cols="3">
        <v-combobox
          v-bind:disabled="orderCustomerData.City == undefined"
          v-model="orderCustomerData.BranshAddress"
          :items="itemsCity"
          :search-input.sync="searchCity"
          item-text="Name"
          @change="SetBransh()"
          hint="Should select"
          label="Start Typing..."
          persistent-hint
        >
          <template v-slot:item="{ index, item }">
            <span large>
              {{ item.Name }}
            </span>
            <v-spacer></v-spacer>
            <span x-large>
              {{ item.Price.toFixed(2) }}
            </span>
          </template>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "<strong>{{ searchCity }}</strong
                  >".
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="3">
        <v-text-field label="Address Detail" v-model="orderCustomerData.AddressDetail"></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field label="Building No" v-model="orderCustomerData.BuildingNo"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field label="Street Name" v-model="orderCustomerData.StreetNo"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field disabled label="DeliveryPrice" v-model="orderCustomerData.DeliveryPrice"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
       <v-col :cols="10"></v-col>
       <v-col :cols="12"  class="d-flex justify-end "><v-btn @click="SaveAddress()" color="primary">Set Address</v-btn></v-col>
    </v-row>

  </div>
</template>

<script>
import { EditCustomerAddress, GetByRestaurntCity } from '@/@core/api/Address'
import VcJordanCitiySelect from '@/components/vc-select/VcJordanCitiySelect.vue'
import { required } from '@core/utils/validation'
import { mdiCheck, mdiFoodApple, mdiFoodAppleOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
export default {
  components: { VcJordanCitiySelect },
  model: {
    prop: 'isNameSet',
    event: 'update:is-name-set',
  },
  props: {
    isNameSet: {
      type: Boolean,
      required: true,
    },
    Customer: {
      type: Object,
      required: true,
    },
    HomesArray: {
      type: Array,
    },
    isEmptyAddress: {
      type: Boolean,
    },
    RestaurantId: { type: Number, required: true },
  },
  watch: {
    Customer: {
      handler(val) {
        this.orderCustomerData = val
        console.log('watcher orderCustomerData', this.orderCustomerData)
      },
      deep: true,
    },
  },
  setup(props, { emit }) {
    const em = ref([{ Name: 'Empty', disabled: true, header: 'Empty' }])
    const orderCustomerData = ref({})
    const searchCity = ref('')
    const key = ref(true)
    const setname = () => {
      emit('update:is-name-set', true)
      console.log('nsme set', props.isNameSet)
    }
    const itemsCity = ref([])
    const SelectCity = () => {
      GetByRestaurntCity({ RestaurantId: props.RestaurantId, City: orderCustomerData.value.City }).then(res => {
        itemsCity.value = res
        orderCustomerData.value.BranshAddress = itemsCity.value.find(f => f.Name == searchCity.value)
        SetBransh()
      })
    }
    const SetBransh = () => {
      if (orderCustomerData.value.BranshAddress != null) {
        orderCustomerData.value.DeliveryPrice = orderCustomerData.value.BranshAddress.Price
        emit('SetBransh', orderCustomerData.value.BranshAddress)
      } else {
        orderCustomerData.value.DeliveryPrice = 0
        emit('SetBransh', { BranchId: '' })
      }
    }
    const toggle = val => {
      console.log('set toggle', val)
      orderCustomerData.value.AddressName = val
      key.value = !key.value
      SelectCity()
    }
    const SetAddress = val => {
      console.log('SetAddress', val)
      if (val == null) {
        orderCustomerData.value.AddressName = ''
        orderCustomerData.value.City = ''
        searchCity.value = ''
        orderCustomerData.value.AddressDetail = ''
        orderCustomerData.value.BuildingNo = ''
        orderCustomerData.value.StreetNo = ''
        SelectCity()
      } else {
        orderCustomerData.value.AddressName = val.Name
        orderCustomerData.value.City = val.City
        searchCity.value = val.BranshAddress
        orderCustomerData.value.AddressDetail = val.AddressDetail
        orderCustomerData.value.BuildingNo = val.BuildingNo
        orderCustomerData.value.StreetNo = val.StreetNo
        SelectCity()
      }
    }
    const SaveAddress = () => {
      let StorageData = JSON.parse(localStorage.getItem('fullOrderData'))
      if (localStorage.getItem('fullOrderData') != undefined && StorageData.CustomerId != orderCustomerData.value.Id) {
        localStorage.removeItem('fullOrderData')
        localStorage.removeItem('orderData')
      }
      SetBransh()
      let add = orderCustomerData.value.CustomerAddresseses
      let branchaddress = orderCustomerData.value.BranshAddress.Name
      let addObj = add.filter(s => s.BranshAddress == branchaddress)
      let obj = addObj[0]
      obj.Name = orderCustomerData.value.AddressName
      obj.City = orderCustomerData.value.City
      obj.BranshAddress = orderCustomerData.value.BranshAddress.Name
      obj.BranshAddressId = orderCustomerData.value.BranshAddress.Id
      obj.AddressDetail = orderCustomerData.value.AddressDetail
      obj.BuildingNo = orderCustomerData.value.BuildingNo
      obj.StreetNo = orderCustomerData.value.StreetNo

      // console.log('add.value',obj)

      EditCustomerAddress(obj).then(res => {
        console.log('res', res)
        for (var i = 0; i < orderCustomerData.value.CustomerAddresseses.length; i++) {
          if (orderCustomerData.value.CustomerAddresseses[i].Id == res.Id) {
            let ob = orderCustomerData.value.CustomerAddresseses[i]
            orderCustomerData.value.CustomerAddresseses.splice(ob, 1)
            orderCustomerData.value.CustomerAddresseses.push(res)
            orderCustomerData.value.DeliveryPrice = orderCustomerData.value.BranshAddress.Price
            console.log('orderCustomerData.final', orderCustomerData.value.CustomerAddresseses)
            SetAddress(res)
            //emit('SetBransh', orderCustomerData.value.BranshAddress)
            emit('SetBranshAddress', orderCustomerData.value)
            break
          }
        }
      })
    }
    return {
      SaveAddress,
      em,
      mdiCheck,
      key,
      toggle,
      mdiFoodAppleOutline,
      mdiFoodApple,
      searchCity,
      itemsCity,
      SelectCity,
      orderCustomerData,
      SetBransh,
      SetAddress,
      required,
      setname,
    }
  },
}
</script>

<style scope>
.item-list-marg {
  /* margin-top:-8px ; */
}
.portrait.v-card {
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
}
</style>
