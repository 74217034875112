<template>
  <div>
    <complain-dialog
      @refetch-data="fetchOrders"
      v-model="isActive"
      :RestaurantId="RestaurantId"
      :Order="orderTemp"
      :Customer="Customer"
    ></complain-dialog>
    <complain-branch-dialog
      @refetch-data="fetchOrders"
      v-model="isComplainBranchActive"
      :RestaurantId="RestaurantId"
      :Customer="Customer"
    ></complain-branch-dialog>
    <v-badge v-bind:color="0 > 0 ? 'error' : 'secondary'" bordered overlap :value="0" :content="0">
      <v-btn
        icon
        v-bind:color="0 > 0 ? 'error' : 'secondary'"
        @click.stop="isComplainBranchActive = !isComplainBranchActive"
      >
        <v-icon small>
          {{ icons.mdiCommentFlash }}
        </v-icon>
      </v-btn>
    </v-badge>
    <v-data-table
      :headers="tableColumns"
      :items="OrderList"
      item-key="Id"
      :items-per-page="5"
      :expanded.sync="expanded"
      show-expand
      :loading="loading"
    >
      <!-- id -->
      <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>

      <!-- Name -->
      <template #[`item.Name`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.Name }}</span>
            <span class="text-xs">{{ item.Description }}</span>
          </div>
        </div>
      </template>
      <!-- CreationTime -->
      <template #[`item.CreationTime`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary font-weight-semibold text-truncate">{{
              filter.parseTime(item.CreationTime, '{y}-{m}-{d} {h}:{i}')
            }}</span>
          </div>
        </div>
      </template>
      <!-- status -->
      <template #[`item.Status`]="{ item }">
        <v-chip
          small
          :color="
            $store.state.status['orders'].find(obj => {
              return obj.value == item.Status
            }).variant || 'primary'
          "
          :class="`${
            $store.state.status['orders'].find(obj => {
              return obj.value == item.Status
            }).variant || 'primary'
          }--text`"
          class="v-chip-light-bg font-weight-semibold text-capitalize"
        >
          {{ item.Status }}
        </v-chip>
      </template>
      <!-- Reorder -->
      <template #[`item.Reorder`]="{ item }">
            <v-btn
            icon
            @click="reorder(item)"
          >
            <v-icon small>
              {{ icons.mdiReload  }}
            </v-icon>
          </v-btn>
        </template>
      <!-- complain -->
      <template #[`item.Complain`]="{ item }">
        <v-badge
          v-bind:color="item.Complains.length > 0 ? 'error' : 'secondary'"
          bordered
          overlap
          :value="item.Complains.length"
          :content="item.Complains.length"
        >
          <v-btn
            icon
            v-bind:color="item.Complains.length > 0 ? 'error' : 'secondary'"
            @click.stop=";(isActive = !isActive), (orderTemp = { ...item })"
          >
            <v-icon small>
              {{ icons.mdiCommentFlash }}
            </v-icon>
          </v-btn>
        </v-badge>
      </template>
      <!-- expandad data -->
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length / 2">
          <v-card outlined>
            <v-card-title>
              <v-row>
                <v-col lg="6" md="4" sm="4" cols="4">
                  <span class="d-flex justify-start"><h3>Order Items</h3></span>
                </v-col>
                <v-col lg="6" md="8" sm="8" cols="8">
                  <vc-change-status
                    v-if="item.Status != 'cancelled' && item.Status != 'cancelrequest'"
                    @refetch-data="fetchOrders"
                    :ObjectId="item.Id"
                    TableName="orders"
                    Status="cancelrequest"
                    :WithDialog="true"
                  />
                   <v-btn
                   v-if="item.Status == 'cancelrequest'"
                    class="pt-0 px-0"
                     :loading="true"
                     :disabled="true"
                   >
                  <template v-slot:loader>
                      <span >Waiting...</span>
                  </template>
                   </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider class="mt-1 mb-1"></v-divider>

            <v-row v-for="(orderTransation, i) in item.OrderTransations" :key="i">
              <v-col lg="7" cols="7"
                >{{ orderTransation.Name }}
                <br />
                <span v-for="(Addon, i) in JSON.parse(orderTransation.Addon)" :key="i"
                  >Addon : {{ Addon.Name }} - ({{ Addon.Price }})<br
                /></span>
                <span v-if="orderTransation.Description != ''">Note : {{ orderTransation.Description }} <br /></span>
              </v-col>
              <v-col lg="2" cols="2">
                {{ orderTransation.Qty }}
              </v-col>
              <v-col>
                {{ (parseFloat(orderTransation.TotalPrice) * orderTransation.Qty).toFixed(2) }}
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-1"></v-divider>

            <v-row>
              <v-col lg="6" md="4" sm="4" cols="4">
                <span class="justify-end text-lg-h6 ms-1">Subtotal</span>
              </v-col>
              <v-col lg="6" md="8" sm="8" cols="8">
                <span class="d-flex justify-end mb-6">
                  {{
                    item.OrderTransations.reduce((prev, cur) => {
                      return prev + parseFloat(cur.TotalPrice) * cur.Qty
                    }, 0).toFixed(2)
                  }}
                  JOD
                </span>
              </v-col></v-row
            >
            <v-divider class="mt-1 mb-1"></v-divider>
            <p v-if="item.Description != null" class="mb-1">Description: {{ item.Description }}</p>
             <p v-if="item.TaxAmount > 0" class="mb-1">Tax Amount: {{ item.TaxAmount }} JD</p>
            <p v-if="item.DeliveryPrice > 0" class="mb-1">DeliveryPrice: {{ item.DeliveryPrice.toFixed(3) }} JD</p>
            <p v-if="item.PromoCode != null" class="mb-1">PromoCode: {{ item.PromoCode }}</p>
            <p v-if="item.PromoCode != null" class="mb-1">PromoCodeAmount: {{ item.PromoCodeAmount }}</p>
            <p class="mb-1">TotalAmount: {{ item.TotalAmount }}</p>
          </v-card>
        </td>
        <td :colspan="headers.length / 2">
          <v-card outlined>
            <v-card-title>
              <v-row>
                <v-col lg="12" md="4" sm="4" cols="4">
                  <span class="d-flex justify-start"><h3>Customer & Address</h3></span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider class="mt-1 mb-1"></v-divider>

            <p class="my-1">Name: {{ item.Name }}</p>
            <p class="mb-1">Payment Type: {{ item.PaymentType }}</p>
            <p class="mb-1">Order Type: {{ item.OrderType }}</p>
            <p v-if="item.ScheduleTimeOrder > '2020-01-01 02:00:00.0000000'" class="mb-1">
              Schedule Time Order: {{ filter.parseTime(item.ScheduleTimeOrder, '{y}-{m}-{d} {h}:{i}') }}
            </p>
            <p class="mb-1">Delivery Type: {{ item.DeliveryType }}</p>
            <p v-if="item.DeliveryCompany != null" class="mb-1">Delivery Company: {{ item.DeliveryCompany }}</p>
            <p class="mb-1">Order Source: {{ item.OrderSource }}</p>
            <p v-if="item.OrderSourceRefNumber != null" class="mb-1">
              Order Source Ref Number: {{ item.OrderSourceRefNumber }}
            </p>

            <p v-if="item.City != null" class="mb-1">City: {{ item.City }}</p>
            <p v-if="item.BranshAddress != null" class="mb-1">Bransh Address: {{ item.BranshAddress }}</p>
            <p v-if="item.AddressDetail != null" class="mb-1">Address Details: {{ item.AddressDetail }}</p>
            <p v-if="item.BuildingNo != null" class="mb-1">Building No: {{ item.BuildingNo }}</p>
            <p v-if="item.StreetNo != null" class="mb-1">Street Name: {{ item.StreetNo }}</p>
          </v-card>
        </td>
      </template>
    </v-data-table>
    <v-snackbar v-model="isFormSubmittedSnackbarVisible" shaped top outlined absolute :light="$vuetify.theme.light" vertical color="error" :timeout="10000">

      <v-card  class=" d-flex justify-start mb-5 mr-5">
          <v-btn
        icon
        @click="isFormSubmittedSnackbarVisible = false"
      >
        <v-icon small>
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
      </v-card>
      <div id="newline">
        {{ snackbarText }}
      </div>
      <v-card  class=" d-flex justify-start mt-10 mr-5">
          <v-btn
        @click="isFormSubmittedSnackbarVisible = false"
        color="info"
        small
      >

         Close
      </v-btn>
      </v-card>
    </v-snackbar>
  </div>
</template>

<script>
import VcChangeStatus from '@/components/vc-status/vc-change-status.vue'
import { GetInActiveByBranch } from '@core/api/Items'
import { GetByCustomerIdRestaurantId } from '@core/api/Order'
import { parseTime } from '@core/utils/filter'
import { mdiClose, mdiCommentFlash, mdiReload } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import ComplainBranchDialog from './ComplainBranchDialog.vue'
import ComplainDialog from './ComplainDialog.vue'

export default {
  components: { ComplainDialog, ComplainBranchDialog, VcChangeStatus },
  props: {
    CustomerId: {
      type: Number,
      required: true,
    },
    RestaurantId: { type: Number, required: true },
    Customer: { type: Object },
  },
    watch: {
      RestaurantId(val) {
     // console.log('RestaurantId', val)
    },
    },
  setup(props, { emit }) {
    const itemIds = ref([])
    const itemList = ref([])
    const RemovedItems = ref([])
    const snackbarText = ref('<h2>This item(s) is inactive:</h2>')
    const isFormSubmittedSnackbarVisible = ref(false)
       const reorder = (item) => {
        let branchId = item.BranchId
        let Itemsarr = {}
        GetInActiveByBranch( {BranchId : branchId}).then( res => {
          itemList.value = res
          itemIds.value = res.map(i => i.Id)
          console.log('itemIds', itemIds.value)
          sessionStorage.clear();
      let cp = item.OrderTransations.slice()

      let order = cp.map(it => {
        let index = it.ItemId
        Itemsarr[index] = index
        if (it.Addon == null){
           return {
            Addon : [],
            Id : it.Id ,
            ItemId : it.ItemId,
            Name : it.Name,
            Description : it.Description ,
            TotalPrice : parseFloat(it.TotalPrice) ,
            Qty : parseInt(it.Qty),
           }
        }
        else {
          return {
            Addon : JSON.parse(it.Addon),
            Id : it.Id ,
            ItemId : it.ItemId,
            Name : it.Name,
            Description : it.Description ,
            TotalPrice : parseFloat(it.TotalPrice) ,
            Qty : parseInt(it.Qty),
        };
        }
      })
    const k = () => {
        console.log('itemIds.value', itemIds.value)
        let x = itemIds.value
        x.forEach( item => {
          console.log('x array',item)
          let index = order.map(e => e.ItemId).indexOf(item);
          if (index > -1) {
            RemovedItems.value.push(order[index])
            console.log('RemovedItems.value', RemovedItems.value)
            order.splice(index, 1);
            let itemremoved = RemovedItems.value.map (element => element.Name)
            let newline = document.getElementById("newline").innerHTML
            snackbarText.value += "<br>" + 'Name: ' + itemremoved
            document.getElementById("newline").innerHTML = snackbarText.value;
            isFormSubmittedSnackbarVisible.value = true
        }
          console.log('order array',order)
        })
      }
      console.log('kkkkkk',k())
      order.push(branchId)
      sessionStorage.setItem('orderData', JSON.stringify([...order]))
       emit('refetch-data')
        })
    }
    const expanded = ref([])
    const isActive = ref(false)
    const isComplainBranchActive = ref(false)
    const orderTemp = ref({ Id: 0 })
    const OrderList = ref([])
    const options = ref({
      sortBy: ['Id'],
      sortDesc: [false],
    })
    const totalOrderList = ref(0)
    const loading = ref(false)
    const loader = ref(true)
    const tableColumns = [
      { text: 'id', value: 'Id' },
      { text: 'Creation Time', value: 'CreationTime' },
      { text: 'Status', value: 'Status', sortable: false },
      { text: 'Reorder', value: 'Reorder', sortable: false },
      { text: 'Complain', value: 'Complain', sortable: false },
    ]
    const fetchOrders = () => {
      GetByCustomerIdRestaurantId({ RestaurantId: props.RestaurantId, CustomerId: props.CustomerId }).then(res => {
        OrderList.value = res
        totalOrderList.value = res.length
      })

    }
    onMounted(() => {
      fetchOrders()
    })
    return {
      snackbarText,
      isFormSubmittedSnackbarVisible,
      RemovedItems,
      itemList,
      itemIds,
      reorder,
      loader,
      fetchOrders,
      isActive,
      isComplainBranchActive,
      orderTemp,
      tableColumns,
      expanded,
      options,
      OrderList,
      totalOrderList,
      loading,
      filter: { parseTime },
      icons: { mdiCommentFlash, mdiReload, mdiClose   },
    }
  },
}
</script>

<style scope>
.portrait.v-card {
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
}


</style>
