<template>
  <v-dialog
    style="height: 1200px"
    width="800px"
    :value="isComplainBranchActive"
    @input="val => $emit('update:is-complain-branch-active', val)"
  >
    <v-card class="transition-fast-in-fast-out" style="height: 100%">
      <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
        <v-card-title>
          <span class="text-big cursor-pointer"> Complaint Restaurnt : {{ ResId }} </span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-text-field
            type="Number"
            autofocus
            class="pb-5 px-5 flex-grow-0 msg-input"
            v-model="Customer.PhoneNumber"
            :rules="[validators.required, validators.lengthValidator(Customer.PhoneNumber, 10)]"

            label="Phone Number"
          ></v-text-field>
          <span class="text-big cursor-pointer">Complain Category</span>

          <select-bransh :RestaurantId="ResId" :rules="[validators.required]" v-model="ComplainData.BranchId" />
          <v-divider class="mt-3 mb-3"></v-divider>
          <span class="text-big cursor-pointer">Complain Category</span>

          <vc-complain-category-select :rules="[validators.required]" v-model="ComplainData.ComplainCategory" />
          <v-divider class="mt-3 mb-3"></v-divider>

          <v-textarea
            v-model="ComplainData.Comment"
            class="mx-2"
            :rules="[validators.required]"
            rows="1"
            hide-details="auto"
            outlined
            label="Comment"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="close"> Cancel </v-btn>
          <v-btn color="success" type="submit"> Save </v-btn>
        </v-card-actions></v-form
      >
    </v-card>
    <v-divider class="mt-6 mb-6"></v-divider>
  </v-dialog>
</template>

<script>
import VcComplainCategorySelect from '@/components/vc-select/VcComplainCategorySelect.vue'
import SelectBransh from '@/views/Branch/SelectBransh.vue'
import { Create } from '@core/api/Complain'
import { parseTime } from '@core/utils/filter'
import { lengthValidator, required } from '@core/utils/validation'
import { mdiCartPlus, mdiCheck, mdiCircle, mdiCircleOutline, mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: { VcComplainCategorySelect, SelectBransh },

  model: {
    prop: 'isComplainBranchActive',
    event: 'update:is-complain-branch-active',
  },
  props: {
    isComplainBranchActive: {
      type: Boolean,
      required: true,
    },
    RestaurantId: {
      type: Number
      },
    Customer: {
      type: Object,
      required: true,
    },
  },
  watch: {
    RestaurantId(val) {
      this.ResId = val
    },
  },
  setup(props, { emit }) {
    const blankData = {
      Id: undefined,
      ComplainCategory: '',
      PhoneNumber: '',
      Comment: '',
      OrderId: undefined,
      BranchId: undefined,
      RestaurantId: undefined,
    }
    const tableColumns = [
      { text: 'id', value: 'Id', sortable: false },
      { text: 'Complain Category', value: 'ComplainCategory', sortable: false },
      { text: 'Comment', value: 'Comment', sortable: false },
      { text: 'Creation Time', value: 'CreationTime', sortable: false },
    ]
    const valid = ref(false)
    const form = ref(null)
    const ResId = ref(props.RestaurantId)
    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }
    const ComplainData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetComplainData = () => {
      ComplainData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-complain-branch-active', false)
    }
    const close = () => {
      resetComplainData()
    }
    const onSubmit = () => {
      ComplainData.value.PhoneNumber = props.Customer.PhoneNumber
      if (valid.value) {
        ComplainData.value.RestaurantId = props.RestaurantId

        Create(ComplainData.value).then(() => {
          emit('refetch-data')
          emit('update:is-complain-branch-active', false)
        })
        resetComplainData()
      } else {
        validate()
      }
    }
    return {
      tableColumns,
      ComplainData,
      mdiPlus,
      ResId,
      onSubmit,
      close,
      validate,
      resetComplainData,
      form,
      validators: { required, lengthValidator },
      valid,
      filter: { parseTime },
      icons: {
        mdiCircleOutline,
        mdiCircle,
        mdiClose,
        mdiClockTimeFourOutline,
        mdiCheck,
        mdiCartPlus,
      },
    }
  },
}
</script>
