<template>
  <div >
   <v-card
    v-scroll.self="onScroll"
    class="overflow-y-auto"
    max-height="400"
  >
      <app-card-actions action-remove>
    <v-banner
      class="justify-center text-h5 font-weight-light"
    >
          <div class=" align-center pb-5">
            <v-text-field
            clearable
              v-model="searchQuery"
              outlined
              placeholder="Search"
              reverse
            ></v-text-field>
          </div>
    </v-banner>
              <div class="add-products-header mb-2 d-none d-md-flex">
          <div class="px-5 flex-grow-1 font-weight-semibold">
            <v-row>
              <v-col
                cols="8"
                md="8"
                class="d-flex justify-end"
              >
                <span>Content</span>
              </v-col>
              <v-col
                cols="4"
                md="4"
                class="d-flex justify-end"
              >
                <span>Header</span>
              </v-col>
            </v-row>
          </div>
          <div class="header-spacer"></div>
        </div>
      <div
        v-for="(NoteListTable, item) in NoteListTable"
        :key="item"

      >
        <v-card
          outlined
          class="d-flex"
        >
          <div class=" flex-grow-1" >
            <v-row>
              <v-col
                cols="8"
                md="8"
                sm="12"
              >
                <v-textarea
                class="text-base pt-3 font-weight-semibold text-xl"
                  reverse
                  auto-grow
                  filled
                  full-width
                  rows="2"
                  row-height="20"
                  solo
                  style="direction: rtl;"
                  v-model="NoteListTable.Content"
                  readonly
                  :value="item.Content"
                  :background-color="$vuetify.theme.dark? '#413c62' :'#ffecb3'"
                  @change="onSubmit(NoteListTable)"
                  @input="val => { NoteListTable.Content }"
                 ></v-textarea>
              </v-col>
              <v-col
                cols="4"
                md="4"
                sm="4"
              >
                <v-text-field

                filled
                shaped
                reverse
                  v-model="NoteListTable.Header"
                  class="text-base pt-3 ma-3 font-weight-semibold text-2xl"
                  readonly
                  :value="item.Header"
                  :background-color="$vuetify.theme.dark? '#413c62' :'#ffecb3'"
                  @input="val => { NoteListTable.Header }"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
       </app-card-actions>
  </v-card>
  </div>
</template>
<script>
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import SelectRestaurant from '@/views/Menu/Restaurant/SelectRestaurant.vue'
import { GetNotes } from '@core/api/Note'
import {
mdiCheckBold,
mdiClose,
mdiCogOutline,
mdiDotsVertical,
mdiEyeOutline,
mdiPencilOutline,
mdiTrendingUp
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'


export default {
  components: {
    SelectRestaurant,
    AppCardActions,
  },
    props: {
    RestaurantId: { type: Number, required: true },
  },
  setup(props) {
    const NoteListTable = ref([])
    const searchQuery = ref('')
    const RestaurantId = ref(null)
    const snackbar = ref(false)
    const isAddActive = ref(false)
    const scrollInvoked = ref(0)
    const onScroll = () => {
        scrollInvoked.value++
      }
    const fetchNotes = () => {
    //let restaurantid = resId.value
    console.log('searchQuery, RestaurantId', props.RestaurantId )
    GetNotes({any:searchQuery.value, ResId: props.RestaurantId})
      .then(response => {
        // handle success
        response == '' ? (NoteListTable.value = []) : (NoteListTable.value = response)
      })
      .catch(error => {
        console.log(error)
      })
  }
    onMounted(() => {
      fetchNotes()
    })
    const isIssueDateMenuOpen = ref(false)
    const isDueDateMenuOpen = ref(false)
   watch([searchQuery, RestaurantId], () => {
     fetchNotes()
   })
    return {
      onScroll,
      scrollInvoked,
      RestaurantId,
      snackbar,
      isAddActive,
      NoteListTable,
      searchQuery,
      fetchNotes,
      icons: {
        mdiClose,
        mdiCogOutline,
        mdiTrendingUp,
        mdiCheckBold,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiPencilOutline,
      },
      isIssueDateMenuOpen,
      isDueDateMenuOpen,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
