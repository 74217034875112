import request from '@core/utils/RequestApi'
import qs from 'qs'

export function CheckPromoCode(data) {
  return request({
    url: '/PromoCode/CheckPromoCode',
    method: 'get',
    params: data,
  })
}
export function Get(data) {
  return request({
    url: '/PromoCode/Get',
    method: 'get',
    params: data,
  })
}
export function Create(data) {
  return request({
    url: '/PromoCode/Create',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function CreatePromos(data) {
  return request({
    url: '/PromoCode/CreatePromos',
    method: 'post',
    data: qs.stringify(data),
  })
}

export function Edit(data) {
  return request({
    url: '/PromoCode/Edit',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Delete(data) {
  return request({
    url: '/PromoCode/Delete',
    method: 'post',
    data: qs.stringify(data),
  })
}
