import request from '@core/utils/RequestApi'
import qs from 'qs'

export function GetNotes(data) {
  return request({
    url: '/Note/GetNotes',
    method: 'get',
    params: data,
  })
}

export function Create(data) {
  return request({
    url: '/Note/Create',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Edit(data) {
  return request({
    url: '/Note/Edit',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Delete(data) {
  return request({
    url: '/Note/Delete',
    method: 'post',
    data: qs.stringify(data),
  })
}

