<template>
  <v-select
    @change="Setmodel"
    v-model="value"
    :items="items"
    label="Restaurant"
    item-text="label"
    item-value="value"
    hide-details
  ></v-select>
</template>

<script>
import { GetRestaurants } from '@core/api/Restaurant'
import { onMounted, ref } from '@vue/composition-api'

export default {
  model: {
    prop: 'RestaurantId',
    event: 'update:restaurant-id',
  },
  props: {
    val: {
      type: Number || null,
      require: false,
    },
  },
  setup(props, { emit }) {
    const value = ref(props.val)
    const Setmodel = () => {
      emit('update:restaurant-id', value.value)
    }
    const all = ref({
      Id: null,
      Name: 'All',
      label: 'All',
      Link: null,
      PhoneNumber: '4564564645',
      Status: 'active',
      Tax: 1,
      Type: null,
      value: null,
    })
    const items = ref([])

    onMounted(() => {
      GetRestaurants().then(response => {
        items.value.push(all.value)
        items.value.push(...response)
        console.log('GetRestaurants', items.value)
      })
    })
    return { items, Setmodel, value, all }
  },
}
</script>
