<template>
  <div id="divid">
    <edit-dialog
      @PushOrderTransationDataEdit="v => EditToLocalStorage( v )"
      @ClickOutside="editout()"
      v-model="isEdit"
      :Item="itemEditFull"
      :SelectedAddons="prevItemEdit"
      :key="isEditKey"
    ></edit-dialog>
    <item-dialog
      @PushOrderTransationData="v => AddToLocalStorage(v)"
      v-model="isActive"
      :Item="itemTemp"
    ></item-dialog>
    <v-card class="overflow-hidden">
      <v-app-bar  color="indigo darken-2" dark short >
        <span class="icon-span">
          <v-app-bar-nav-icon>
            <v-btn icon @click="closereveal">
              <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
            </v-btn>
          </v-app-bar-nav-icon></span
        >
        <span class="title-span">
          <v-app-bar-title>{{ OrderBranch.Name }}</v-app-bar-title>
        </span>
        <!-- <v-spacer></v-spacer> -->
      </v-app-bar>
      <v-container>
        <!-- search -->
        <v-row class="mt-1">
          <v-col cols="11">
          <v-text-field
          v-model="searchItemQuery"
          clearable
          placeholder="Search By Name Or Description"
          outlined
          hide-details
          dense >
        </v-text-field>
          </v-col>
          <v-col cols="1" class="d-flex align-center">
            <v-card
             class="d-flex align-center justify-center pr-2"
             tile
           >
           <v-btn color="secondary" small @click="OrderRetrieve()">
              Last Order
            </v-btn>
            </v-card>
          </v-col>
        </v-row>
        <br />

        <div class="txtsize offwhite--text">
          <v-row>
            <!-- ################## Categories ****************** Section ################## -->
            <v-col lg="2" sm="12" md="4" cols="12">
              <v-card outlined>
                <v-card-title>Categories</v-card-title>
                <v-divider class="mt-4"></v-divider>
                <v-list>
                  <v-list-item-group v-model="model">
                    <v-list-item v-for="item in BranchCategory" :key="item.Id">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.Name"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
            <!-- ################## Items ****************** Section ################## -->
            <v-col cols="12" lg="6" md="8" sm="12">
              <v-expansion-panels focusable v-model="model">
                <v-expansion-panel v-for="cat in BranchCategory" :key="cat.Id">
                  <v-expansion-panel-header
                    ><h3>{{ cat.Name }}</h3></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item-group v-model="modelItem">
                      <!--  || -->
                        <v-list-item
                          v-for="item in cat.Items.filter(
                            S =>
                             !searchItemQuery ||
                              S.Name.toLowerCase().includes(searchItemQuery.toLowerCase()) ||
                              (S.Description !=null && S.Description.includes(searchItemQuery.toLowerCase()) )

                          )"
                          :key="item.Id"
                          @click="open(item)"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              <span class="text-big cursor-pointer">
                                <v-avatar
                                  :color="item.avatar ? '' : 'primary'"
                                  :class="item.avatar ? '' : 'v-avatar-light-bg '"
                                  size="32"
                                >
                                  <vc-image
                                    :width="32"
                                    :height="32"
                                    :object-id="item.Id"
                                    table-name="Item"
                                    :label-text="item.Name"
                                  /> </v-avatar
                                >&ensp;<strong>{{ item.Name }}</strong
                                >&ensp;&ensp;</span
                              >
                              <v-chip
                                :color="
                                  $store.state.status['items'].find(obj => {
                                    return obj.value == item.Status
                                  }).variant
                                "
                                dark
                                  label
                                small
                              >
                              <v-avatar left v-if ="item.Status == 'inactive'">
                               <v-icon> {{icons.mdiClose }}</v-icon>
                             </v-avatar>
                             {{ item.Status }}
                             <v-avatar right v-if ="item.Status == 'inactive'">
                               <v-icon> {{icons.mdiClose }}</v-icon>
                             </v-avatar>
                              </v-chip>
                              <span class="me-3" style="float: right">
                                ({{ item.Price == 0 ? 'Price On  Selection' : item.Price }})
                                </span >
                              <!-- <v-row no-gutters class="pt-2 pb-2">
                                    <v-col cols="1" >
                                        <v-badge
                                        avatar
                                        overlap
                                        dot
                                        bottom
                                        :color="$store.state.status['items'].find(obj => {
                                            return obj.value == item.Status
                                            }).variant"
                                        offset-x="10"
                                        offset-y="10"
                                      >
                                      <v-avatar
                                      :color="item.avatar ? '' : 'primary'"
                                      :class="item.avatar ? '' : 'v-avatar-light-bg '"
                                      size="36"
                                    >
                                      <vc-image
                                        :width="36"
                                        :height="36"
                                        :object-id="item.Id"
                                        table-name="Item"
                                        :label-text="item.Name"
                                      />
                                    </v-avatar>
                                      </v-badge>
                                    </v-col>
                                    <v-col cols="8"  >
                                      <span style="float: left" class="pr-1">
                                      <h4><strong >{{ item.Name }}</strong></h4>
                                    </span>
                                    </v-col>
                                    <v-col cols="3">
                                      <span style="float: right" class="pr-1">
                                          ({{ item.Price == 0 ? 'Price On  Selection' : item.Price }})
                                        </span>
                                    </v-col>
                              </v-row> -->
                            </v-list-item-title>
                            <v-list-item-subtitle v-text="item.Description"></v-list-item-subtitle>
                            <!-- <v-list-item-subtitle v-text="item.Description" class="subtitle-1"></v-list-item-subtitle> -->
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <!-- ################## Cart ****************** Section ################## -->
            <v-col cols="12" lg="4" md="12" sm="12">
              <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
                <v-card outlined>
                  <v-card-title>
                    <v-row>
                      <v-col lg="6" md="4" sm="4" cols="4">
                        <span class="d-flex justify-start"><h3>Cart</h3></span>
                      </v-col>
                      <v-col lg="6" md="8" sm="8" cols="8">
                        <span class="d-flex justify-end"><h6>PrepareTime: 30 min</h6></span>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider class="mt-1 mb-1"></v-divider>

                  <v-row v-for="(item, i) in ItemList" :key="i">
                     <v-col @click="ItemEditDialog(item,i)" lg="6" cols="6"
                      ><strong >{{ item.Name }}</strong>
                      <br />
                      <span v-if="item.Addon != null">
                      <span v-for="(Addon, i) in item.Addon.filter(f => f.IsDependence == false)" :key="i"
                        >
                        Addon : {{ Addon.Name }} - ({{ Addon.Price }})<br
                      /></span></span>
                      <span v-if="item.Description != ''">Note : {{ item.Description }} <br /></span>
                    </v-col>
                    <v-col lg="3" cols="3">
                      <plus-minus-field
                        :rules="[validators.minQtyValidator(ItemList[i].Qty, 1)]"
                        :max="100"
                        :min="1"
                        label="Qty"
                        v-model="ItemList[i].Qty"
                      />
                    </v-col>
                    <v-col>
                      {{ (parseFloat(item.TotalPrice) * item.Qty).toFixed(2) }}
                      <v-btn icon color="error" @click.stop="RemoveFromLocalStorage(i)">
                        <v-icon small>
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-1 mb-1"></v-divider>

                  <v-row>
                    <v-col lg="6" md="4" sm="4" cols="4">
                      <span class="justify-end text-lg-h6 ms-1">Subtotal</span>
                    </v-col>
                    <v-col lg="6" md="8" sm="8" cols="8">
                      <span class="d-flex justify-end mb-6">
                        {{
                          ItemList.reduce((prev, cur) => {
                            return prev + parseFloat(cur.TotalPrice) * cur.Qty
                          }, 0).toFixed(2)
                        }}
                        JOD
                      </span>
                    </v-col></v-row
                  >
                  <v-divider class="mt-1 mb-1"></v-divider>
                  <!-- ************** Delivery Type ************** -->
                  <!-- @PushOrderTransationData="v => AddToLocalStorage(v)" -->
                  <v-row>
                    <v-col lg="4" cols="4">
                      <span class="ml-5 mr-5 align-self-end font-weight-semibold"> Delivery Type </span>
                    </v-col>
                    <v-col lg="8" cols="8">
                      <v-radio-group
                        hide-details="auto"
                        :rules="[validators.required]"
                        v-model="orderData.DeliveryType"
                        row
                        @change="SetDeliveryPrice"
                      >
                        <v-radio
                          v-for="option in DeliveryType"
                          :key="option.Id"
                          :label="option.Name"
                          :value="option.Name"
                          :disabled="option.dis"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row v-if="orderData.DeliveryType == 'Delivery'">
                    <v-col lg="4" cols="4">
                      <span class="ml-4 mr-4 align-self-end font-weight-semibold font-100" style="font-size:10pt"> Delivery Company</span>
                    </v-col>
                    <v-col lg="8" cols="8">
                      <v-radio-group
                        :rules="[validators.required]"
                        hide-details="auto"
                        class="mb-6"
                        v-model="orderData.DeliveryCompany"
                        row
                        mandatory
                      >
                        <v-radio
                          v-for="option in DeliveryCompanies"
                          :key="option.Id"
                          :label="option.Name"
                          :value="option.Name"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-divider class="mt-1 mb-1"></v-divider>

                  <v-row>
                    <v-col lg="4" cols="4">
                      <span class="ml-5 mr-5 align-self-end font-weight-semibold">Order Source</span>
                    </v-col>
                    <v-col lg="8" cols="8" class="align-self-start">
                      <v-radio-group
                        hide-details="auto"
                        :rules="[validators.required]"
                        v-model="orderData.OrderSource"
                        row
                      >
                        <v-radio
                          v-for="option in OrderSource"
                          :key="option.Id"
                          :label="option.Name"
                          :value="option.Name"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row v-if="orderData.OrderSource != 'CallCenter' && orderData.OrderSource != 'RestaurantApp'">
                    <v-col lg="12" cols="12">
                      <span class="ml-5 mr-5 align-self-center"
                        ><v-text-field
                          v-model="orderData.OrderSourceRefNumber"
                          outlined
                          color="primary"
                          placeholder="Referance Number"
                          label="Referance Number"
                          hide-details="auto"
                        ></v-text-field
                      ></span> </v-col
                  ></v-row>

                  <v-divider class="mt-1 mb-1"></v-divider>

                  <v-row>
                    <v-col lg="4" cols="4">
                      <span class="ml-5 mr-5 align-self-end font-weight-semibold">Payment Type</span>
                    </v-col>
                    <v-col lg="8" cols="8" class="pb-1 align-self-start">
                      <v-radio-group
                        hide-details="auto"
                        :rules="[validators.required]"
                        v-model="orderData.PaymentType"
                        row
                      >
                        <v-radio
                          v-for="option in PaymentType"
                          :key="option.Id"
                          :label="option.Name"
                          :value="option.Name"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-divider class="mt-1 mb-1"></v-divider>

                  <v-row>
                    <v-col lg="4" cols="4">
                      <span class="ml-5 mt-5 font-weight-semibold">Order Type</span>
                    </v-col>
                    <v-col lg="8" cols="8" class="pb-1 align-self-start">
                      <v-radio-group
                        hide-details="auto"
                        :rules="[validators.required]"
                        v-model="orderData.OrderType"
                        row
                      >
                        <v-radio
                          v-for="option in OrderType"
                          :key="option.Id"
                          :label="option.Name"
                          :value="option.Name"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row v-if="orderData.OrderType == 'Schedule'">
                    <v-col lg="12" cols="12" class="pb-1 align-self-start">
                      <v-datetime-picker label="Schedule Datetime" v-model="orderData.ScheduleTimeOrder">
                        <template slot="dateIcon">
                          <v-icon>{{ icons.mdiCalendarRange }}</v-icon>
                        </template>
                        <template slot="timeIcon">
                          <v-icon>{{ icons.mdiClockOutline }}</v-icon>
                        </template></v-datetime-picker
                      >
                    </v-col>
                  </v-row>
                  <v-row
                    ><v-col lg="12" cols="12">
                      <v-textarea
                        v-model="orderData.Description"
                        class="mx-2"
                        rows="1"
                        hide-details="auto"
                        outlined
                        label="Order notes"
                      ></v-textarea> </v-col
                    ><v-col lg="12" cols="12">
                      <v-text-field
                        v-model="orderData.PromoCode"
                        hide-details="auto"
                        @blur="SetPromoCode"
                        outlined
                        label="Promo Code"
                      ></v-text-field> </v-col
                  ></v-row>

                  <v-row>
                      <v-col  v-if="Tax > 1" lg="4" md="4" sm="4" cols="4" class="ml-5">
                        <span class="d-flex justify-start danger--text font-weight-semibold"
                        ><span class="text-xl-body-1 font-weight-bold">Tax</span></span
                      >
                    </v-col>
                    <v-col v-if="Tax > 1" lg="6" md="8" sm="8" cols="8" class="ml-5">
                      <span class="d-flex justify-end"
                        ><span class="text-md font-weight-semibold ">
                          {{
                            ((
                              (ItemList.reduce((prev, cur) => {
                                return prev + parseFloat(cur.TotalPrice) * cur.Qty
                              }, 0) +
                              orderData.PromoCodeAmount) -
                              (ItemList.reduce((prev, cur) => {
                                return prev + parseFloat(cur.TotalPrice) * cur.Qty
                              }, 0) * orderData.PromoCodePercentage)
                              )* (Tax-1)).toFixed(2)
                          }} JOD</span
                        ></span
                      >
                    </v-col>
                      <v-col v-if="orderData.DeliveryType == 'Delivery'" lg="4" md="4" sm="4" cols="4" class="ml-5">
                      <span class="d-flex justify-start danger--text font-weight-semibold"
                        ><span class="text-xl-body-1 font-weight-bold">Delivery Fee</span></span
                      >
                    </v-col>

                    <v-col v-if="orderData.DeliveryType == 'Delivery'" lg="6" md="8" sm="8" cols="8" class="ml-5">
                      <span class="d-flex justify-end"
                        ><span class="text-lg font-weight-semibold error--text">
                          {{ orderData.DeliveryPrice }} JOD</span
                        ></span
                      >
                    </v-col>
                    <v-col lg="4" md="4" sm="4" cols="4" class="mb-5 ml-5">
                      <span class="d-flex justify-start"
                        ><span class="text-xl-body-1 font-weight-bold">Total Amount</span></span
                      >
                    </v-col>
                    <v-col lg="6" md="8" sm="8" cols="8" class="mb-5 ml-5">
                      <span class="d-flex justify-end">
                        <span

                          class="text-xl font-weight-semibold error--text"
                          @change="CallPrice()"
                        >
                          {{
                            (((
                              (ItemList.reduce((prev, cur) => {
                                return prev + parseFloat(cur.TotalPrice) * cur.Qty
                              }, 0) +
                              orderData.PromoCodeAmount) -
                              (ItemList.reduce((prev, cur) => {
                                return prev + parseFloat(cur.TotalPrice) * cur.Qty
                              }, 0) * orderData.PromoCodePercentage
                                )) * Tax
                            )+
                              orderData.DeliveryPrice).toFixed(2)
                          }}
                          JOD
                        </span>
                        </span>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-row justify="space-around">
                      <v-btn
                        x-large
                        class="d-flex pa-2 pl-10 pr-10"
                        :disabled="ItemList.length <= 0"
                        type="submit"
                        color="warning"
                        :loading="loading"
                      >
                        <v-icon>{{ icons.mdiCartCheck }}</v-icon
                        >Check Out</v-btn
                      ></v-row
                    >
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card>
    <v-snackbar v-model="isFormSubmittedSnackbarVisible" multi-line color="error" min-height="100px" vertical :light="$vuetify.theme.dark"> <h2 class="pl-16 pr-16 pt-8 pb-8">{{ snackbarText }} </h2></v-snackbar>
  </div>
</template>

<script>
import { CreateWithCheck } from '@/@core/api/Customers'
import { Create } from '@/@core/api/Order'
import { CheckPromoCode } from '@/@core/api/PromoCode'
import VcImage from '@/components/vc-avatar/VcImage.vue'
import PlusMinusField from '@/components/vc-input/PlusMinusField.vue'
import pushSubscription from '@/components/vc-push/pushSubscription'
import router from '@/router'
import { GetAddonsByItem, GetItemAndAddonsByItem } from '@core/api/Addons'
import { NotifyAsync, Send } from '@core/api/Chat'
import { minQtyValidator, required } from '@core/utils/validation'
import { DateTimeFormatter, Instant, LocalDateTime } from '@js-joda/core'
import { mdiArrowLeft, mdiCalendarRange, mdiCartCheck, mdiClockOutline, mdiClose, mdiDeleteOutline } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import Pusher from 'pusher-js'
import EditDialog from '../Components/EditDialog.vue'
import ItemDialog from '../Components/ItemDialog.vue'

export default {
  components: {
    VcImage,
    ItemDialog,
    PlusMinusField,
    EditDialog,
  },
  props: {
    OrderBranch: {
      type: Object,
      required: true,
    },
    Customer: {
      type: Object,
    },
    closereveal: {
      type: Function,
    },
    BranchCategory: {
      type: Array,
    },
    BranchCategorytotal: {
      type: Number,
    },
    isNameSet: {
      type: Boolean,
      required: true,
    },
    isAddress: {
      type: Boolean,
    },
  },

  setup(props, { emit }) {
    let promos = []
    const Tax = ref(1)
    const messages = ref([])
    const model = 0
    const modelItem = -1
    const searchItemQuery = ref('')
    const snackbarText = ref('')
    const isFormSubmittedSnackbarVisible = ref(false)
    const isActive = ref(false)
    const isEdit = ref(false)
    const itemTemp = ref({ Id: 0, Addons: [] })
    const prevItemEdit = ref({ Id: 0, Addon: [] })
    const prevItemToRemove = ref(0)
    const itemEditFull = ref({ Id: 0, Addons: [] })
    const valid = ref(false)
    const isEditKey = ref(false)
    const form = ref(null)
    const loading = ref(false)
    const ItemList = ref([])
    const { checkUrl } = pushSubscription()
    const blankData = {
      Id: undefined,
      Name: '',
      CreationTime: undefined,
      PaymentType: 'Cash on Delivery',
      Status: 'pinding',
      OrderType: 'Non Schedule',
      ScheduleTimeOrder: '',
      DeliveryType: '',
      DeliveryCompany: '',
      OrderSource: 'CallCenter',
      OrderSourceRefNumber: '',
      DeliveryPrice: 0,
      PromoCode: '',
      PromoCodeAmount: 0,
      TotalAmount: 0,
      Description: '',
      City: '',
      BranshAddress: '',
      BranshAddressId: undefined,
      AddressDetail: '',
      BuildingNo: '',
      StreetNo: '',
      BranchId: props.OrderBranch.Id,
      CustomerId: undefined,
      PromoCodeId: undefined,
      OrderTransations: [],
      PromoCodePercentage: 0,
      OrderPromos: [],
    }
    const orderData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetForm = () => {
      form.value.reset()
    }
    const resetOrderData = () => {
      orderData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
    }
    const validate = () => {
      form.value.validate()
    }
    const lastarr = ref([])
    const ItemEditDialog = (item, i) => {
      lastarr.value = []
      prevItemToRemove.value = i
      prevItemEdit.value = JSON.parse(JSON.stringify(item))
      GetItemAndAddonsByItem({ ItemId: item.ItemId }).then(res => {
        itemEditFull.value = res.ItemData
        itemEditFull.value.Addons = res.ItemAddon
        let allarr = JSON.parse(JSON.stringify(itemEditFull.value.Addons))
        let selected = JSON.parse(JSON.stringify(prevItemEdit.value.Addon))

        allarr.forEach((element, i) => {
          let filteredArr = selected.filter(el => el.AddonId == element.Id)
          if (filteredArr.length > 0) {
            switch (element.IsMulti) {
              case true:
                lastarr.value[i] = filteredArr
                break
              case false:
                lastarr.value.push(...filteredArr)
                break
            }
          } else {
            lastarr.value[lastarr.value.length] = [{}]
          }
        })
        prevItemEdit.value.Addon = lastarr.value
        console.log('GetItemAndAddonsByItem', prevItemEdit.value)
        isEdit.value = true
      })
    }
    const open = item => {
      console.log('item open', item)
      if (item.Status === 'active') {
        GetAddonsByItem({ ItemId: item.Id }).then(res => {
          itemTemp.value = item
          itemTemp.value.Addons = res
          isActive.value = true
        })
      }
    }
    const editout = () => {
      lastarr.value = []
      isEditKey.value = !isEditKey.value
      isEdit.value = false
    }
    const EditToLocalStorage = v => {
      console.log('Edit  ToLocalStorage', v)
      ItemList.value.splice(prevItemToRemove.value, 1)
      ItemList.value.splice(prevItemToRemove.value, 0, v)
      //ItemList.value.push(v)
      console.log('newarrnewarr', ItemList.value)
      localStorage.removeItem('orderData')
      localStorage.setItem('orderData', JSON.stringify([...ItemList.value]))
      localStorage.removeItem('fullOrderData')
      if (props.Customer.Id == undefined) {
        localStorage.setItem('fullOrderData', JSON.stringify(orderData.value))
      } else {
        orderData.value.CustomerId = props.Customer.Id
        localStorage.setItem('fullOrderData', JSON.stringify(orderData.value))
      }
      //localStorage.setItem('fullOrderData', JSON.stringify(orderData.value))
      console.log('edit fullOrderData', orderData.value)

      isEditKey.value = !isEditKey.value
      //isEdit.value = false
      CallPrice()
    }
    const AddToLocalStorage = v => {
      console.log('AddToLocalStorage', v)
      ItemList.value.push(v)
      localStorage.removeItem('orderData')
      localStorage.setItem('orderData', JSON.stringify([...ItemList.value]))
      localStorage.removeItem('fullOrderData')
      if (props.Customer.Id == undefined) {
        localStorage.setItem('fullOrderData', JSON.stringify(orderData.value))
      } else {
        orderData.value.CustomerId = props.Customer.Id
        localStorage.setItem('fullOrderData', JSON.stringify(orderData.value))
      }
      console.log('add fullOrderData', orderData.value)

      // localStorage.setItem('fullOrderData', JSON.stringify(orderData.value))
    }
    const RemoveFromLocalStorage = i => {
      ItemList.value.splice(i, 1)
      localStorage.removeItem('orderData')
      localStorage.setItem('orderData', JSON.stringify([...ItemList.value]))
    }
    const CallPrice = () => {
      orderData.value.TotalAmount =
        (ItemList.value.reduce((prev, cur) => {
          return prev + parseFloat(cur.TotalPrice) * cur.Qty
        }, 0) +
          orderData.value.PromoCodeAmount -
          ItemList.value.reduce((prev, cur) => {
            return prev + parseFloat(cur.TotalPrice) * cur.Qty
          }, 0) *
            orderData.value.PromoCodePercentage) *
          Tax.value +
        orderData.value.DeliveryPrice
    }
    const SetPromoCode = () => {
      CheckPromoCode({ Code: orderData.value.PromoCode }).then(res => {
        if (res != false) {
          promos.push(res)
          if (res.Option == 'Percentage') {
            orderData.value.PromoCodePercentage += res.Amount / 100
          }
          if (res.Option == 'Value') {
            if (res.Type == 'Discount') orderData.value.PromoCodeAmount -= res.Amount
            if (res.Type == 'Increase') orderData.value.PromoCodeAmount += res.Amount
          }
          orderData.value.PromoCode = ''
        } else {
          orderData.value.PromoCode = ''
        }
        CallPrice()
      })
    }
    const SetDeliveryPrice = () => {
      if (orderData.value.DeliveryType == 'Delivery') {
        console.log('customer Name', props.Customer.BranshAddress)
        if (props.Customer.BranshAddress == undefined) {
          snackbarText.value = 'Select Address'
          isFormSubmittedSnackbarVisible.value = true
          orderData.value.DeliveryType = null
        } else {
          orderData.value.DeliveryPrice = props.Customer.DeliveryPrice
          orderData.value.City = props.Customer.City
          orderData.value.BranshAddress = props.Customer.BranshAddress.Name
          orderData.value.BranshAddressId = props.Customer.BranshAddress.Id
          orderData.value.AddressDetail = props.Customer.AddressDetail
          orderData.value.BuildingNo = props.Customer.BuildingNo
          orderData.value.StreetNo = props.Customer.StreetNo
        }
      } else {
        orderData.value.DeliveryPrice = 0
        orderData.value.City = ''
        orderData.value.BranshAddress = ''
        orderData.value.AddressDetail = ''
        orderData.value.BuildingNo = ''
        orderData.value.StreetNo = ''
        orderData.value.BranshAddressId = undefined
      }
    }
    const CustomerAddress = ref([])
    const onSubmit = () => {
      console.log('customer Name', props.Customer)
      if (
        props.Customer.Name == '' ||
        props.Customer.Name == '' ||
        props.Customer.Name == null ||
        props.Customer.Name == undefined
      ) {
        snackbarText.value = 'Please Enter Customer Name'
        isFormSubmittedSnackbarVisible.value = true
        return false
      }
      orderData.value.OrderTransations = ItemList.value
      if (valid.value) {
        try {
          loading.value = true
          if (orderData.value.PromoCodePercentage > 0) {
            orderData.value.TotalAmount =
              (orderData.value.OrderTransations.reduce((prev, cur) => {
                return prev + parseFloat(cur.TotalPrice) * cur.Qty
              }, 0) +
                orderData.value.PromoCodeAmount -
                orderData.value.OrderTransations.reduce((prev, cur) => {
                  return prev + parseFloat(cur.TotalPrice) * cur.Qty
                }, 0) *
                  orderData.value.PromoCodePercentage) *
                Tax.value +
              orderData.value.DeliveryPrice
          }
          if (orderData.value.PromoCodePercentage == 0) {
            orderData.value.TotalAmount =
              orderData.value.OrderTransations.reduce((prev, cur) => {
                return prev + parseFloat(cur.TotalPrice) * cur.Qty
              }, 0) *
                Tax.value +
              orderData.value.DeliveryPrice +
              orderData.value.PromoCodeAmount
          }
          orderData.value.OrderTransations = orderData.value.OrderTransations.map(x => {
            return {
              Id: undefined,
              Qty: x.Qty,
              Name: x.Name,
              TotalPrice: x.TotalPrice,
              Description: x.Description,
              Addon: JSON.stringify(x.Addon),
              ItemId: x.ItemId,
            }
          })
          orderData.value.OrderPromos = promos.map(item => {
            return {
              PromoCodeID: item.Id,
              Name: item.Name,
            }
          })
          if (props.Customer.CustomerAddresseses == undefined) props.Customer.CustomerAddresseses = []
          console.log('props.Customer.AddressName', props.Customer.AddressName)
          if (props.Customer.AddressName != '' && props.Customer.AddressName != undefined) {
            let findAddresses = props.Customer.CustomerAddresseses.findIndex(f => f.Name == props.Customer.AddressName)
            console.log('findAddresses', findAddresses)
            if (findAddresses == -1) {
              props.Customer.CustomerAddresseses.push({
                Name: props.Customer.AddressName,
                City: props.Customer.City,
                BranshAddress: props.Customer.BranshAddress.Name,
                BranshAddressId: props.Customer.BranshAddress.Id,
                AddressDetail: props.Customer.AddressDetail,
                BuildingNo: props.Customer.BuildingNo,
                StreetNo: props.Customer.StreetNo,
              })
            }
          }
          console.log('JavaScript', CustomerAddress.value)
          let resid = router.currentRoute.params.id
          if (Tax.value > 1) {
            let orderBefor = parseFloat(
              ((orderData.value.TotalAmount - orderData.value.DeliveryPrice) / Tax.value).toFixed(2),
            )
            orderData.value.TaxAmount = parseFloat((orderData.value.TotalAmount - orderBefor).toFixed(2))
          }
          CreateWithCheck({ collection: props.Customer }).then(Cres => {
            orderData.value.CustomerId = Cres.Id
            console.log('Customer', props.Customer)
            /// Create Order
            orderData.value.Name = props.Customer.Name
            orderData.value.BranchId = props.OrderBranch.Id
            console.log('OrderBranch', props.OrderBranch)
            console.log('orderData', orderData.value)

            orderData.value.ScheduleTimeOrder = LocalDateTime.ofInstant(
              Instant.ofEpochMilli(orderData.value.ScheduleTimeOrder),
            ).format(DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'))
            console.log('orderData.value', orderData.value)
            Create(orderData.value).then(res => {
              console.log('branch id ', orderData.value.BranchId)
              let BrId = orderData.value.BranchId
              NotifyAsync({ BranchId: BrId })
              NewOrder()
              if (res) {
                // orderData.value.OrderPromos.forEach(item => {
                //   let obj = { Id: undefined, PromoCodeID: item.Id, OrderId: res.Id, PromoCode: item, Order: res }

                // })
                resetOrderData()
                console.log('loading', loading.value)
                loading.value = false
                console.log('loading', loading.value)
                router.push({ name: 'AgentHome' })
              } else {
                snackbarText.value = "Can't Save Order ."
                isFormSubmittedSnackbarVisible.value = true
              }
              //    emit('refetch-data')
              // emit('update:is-add-active', false)
            })
            sessionStorage.clear()
          })
        } catch {
          //
          console.log('error while saving')
        }
      } else {
        validate()
      }
    }
    const deliveryDisable = ref(false)

    const DeliveryType = [
      { Id: 1, Name: 'Pick Up', dis: false },
      { Id: 2, Name: 'Delivery', dis: deliveryDisable.value },
      { Id: 3, Name: 'Dine In', dis: false },
    ]
    const OrderSource = [
      { Id: 1, Name: 'CallCenter' },
      { Id: 2, Name: 'RestaurantApp' },
      { Id: 3, Name: 'Talabat' },
      { Id: 4, Name: 'Careem' },
      { Id: 5, Name: 'Other' },
    ]
    const PaymentType = [
      { Id: 1, Name: 'Cash on Delivery' },
      { Id: 2, Name: 'Credit Card' },
    ]
    const OrderType = [
      { Id: 1, Name: 'Schedule' },
      { Id: 2, Name: 'Non Schedule' },
    ]
    const DeliveryCompanies = [
      { Id: 1, Name: 'No Company' },
      { Id: 2, Name: 'Nashmi Delivery' },
    ]
    const NewOrder = async () => {
      let BranId = orderData.value.BranchId
      await Send({
        username: BranId,
        body: 'Update',
      })
    }
    const OrderRetrieve = () => {
      //orderData.value = []
      let dataOrder = JSON.parse(localStorage.getItem('fullOrderData'))
      console.log('orderData.value.BranchId', dataOrder)
      if (dataOrder.BranchId == props.OrderBranch.Id) {
        orderData.value = { ...dataOrder }
        ItemList.value = []
        let prevOrder = JSON.parse(localStorage.getItem('orderData'))
        console.log('prevOrder', prevOrder)
        ItemList.value = prevOrder.slice()
      } else {
        snackbarText.value = 'No Orders for this Branch'
        isFormSubmittedSnackbarVisible.value = true
      }
    }
    const channelSubscribe = () => {
      let result = checkUrl()
      Pusher.logToConsole = true
      const pusher = new Pusher('01b4d5c8507d46578a54', {
        cluster: 'ap2',
      })
      if (result == false) {
        pusher.disconnect()
      } else {
        const channel = pusher.subscribe('my-channel')
        channel.bind('my-event', data => {
          messages.value.push(data)
        })
      }
    }
    onMounted(() => {
      console.log('Customer', props.Customer)
      Tax.value = props.OrderBranch.Tax
      console.log('Tax.value', Tax.value)
      orderData.value = JSON.parse(JSON.stringify(blankData))
      console.log('mounted', props.OrderBranch.Id)
      let brId = props.OrderBranch.Id
      orderData.value.BranchId = brId
      channelSubscribe()
      if (sessionStorage.getItem('orderData') != undefined) {
        ItemList.value = []
        let order = JSON.parse(sessionStorage.getItem('orderData')).slice()
        console.log('order.BranchId', order)
        console.log('brId', brId)
        if (order[order.length - 1] == brId) {
          let x = order.length
          ItemList.value = order.slice(-x, x - 1)
          let or = ItemList.value.map(item => {
            return {
              Description: item.Description,
              ItemId: item.ItemId,
              Name: item.Name,
              Qty: item.Qty,
              TotalPrice: item.TotalPrice,

              Addon:
                item.Addon == undefined
                  ? item.Addon
                  : item.Addon.map(i => {
                      return {
                        Id: i.Id,
                        AddonId: i.AddonId,
                        Description: i.Description,
                        IsDependence: i.IsDependence == true ? (item.Name = item.Name + ' - ' + i.Name) : false,
                        IsMulti: i.IsMulti,
                        IsRequired: i.IsRequired,
                        Name: i.Name,
                        Price: i.Price,
                        Status: i.Status,
                      }
                    }),
            }
          })
          console.log('or', ItemList.value)
        } else {
          snackbarText.value = 'The Order is from Different Branch'
          isFormSubmittedSnackbarVisible.value = true
        }
      }
      const element = document.getElementById('divid')
      element.addEventListener('change', myFunction)
      function myFunction() {
        if (localStorage.getItem('fullOrderData') != undefined) localStorage.removeItem('fullOrderData')
        localStorage.setItem('fullOrderData', JSON.stringify(orderData.value))
      }
      if (props.isAddress == true) {
        console.log('props.OrderBranch.Id', props.OrderBranch.Id)
        console.log('props.Customer.BranshAddress.BranchId', props.Customer)

        if (props.OrderBranch.Id == props.Customer.BranshAddress.BranchId) {
          OrderRetrieve()
        }
      }
    })
    return {
      CustomerAddress,
      deliveryDisable,
      // detect,
      channelSubscribe,
      checkUrl,
      lastarr,
      editout,
      isEditKey,
      prevItemToRemove,
      Tax,
      ItemEditDialog,
      OrderRetrieve,
      ItemList,
      RemoveFromLocalStorage,
      AddToLocalStorage,
      EditToLocalStorage,
      messages,
      NewOrder,
      loading,
      promos,
      searchItemQuery,
      orderData,
      DeliveryCompanies,
      PaymentType,
      OrderType,
      OrderSource,
      DeliveryType,
      model,
      modelItem,
      isActive,
      isEdit,
      itemTemp,
      prevItemEdit,
      itemEditFull,
      snackbarText,
      isFormSubmittedSnackbarVisible,
      SetDeliveryPrice,
      open,
      SetPromoCode,
      onSubmit,
      validate,
      resetOrderData,
      form,
      validators: { required, minQtyValidator },
      valid,
      icons: {
        mdiArrowLeft,
        mdiCartCheck,
        mdiDeleteOutline,
        mdiCalendarRange,
        mdiClockOutline,
        mdiClose,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.v-input__slot {
  margin-bottom: 0px;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
.title-span {
  color: #ffffffde;
  margin-left: 10px;
  margin-top: -3px;
  font-size: 25px;
}
.icon-span {
  color: #ffffffde;
  margin-top: 5px;
  margin-right: 5px;
}
.txtsize {
  font-size: 15px;
}
.badgesize {
  $badge-dot-height: 18px;
}
</style>
