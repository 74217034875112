import request from '@core/utils/RequestApi'
import qs from 'qs'

export function CreateAddon(data) {
  return request({
    url: '/Addon/CreateAddon',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Edit(data) {
  return request({
    url: '/Addon/Edit',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function GetAddonsByBranch(data) {
  return request({
    url: '/Addon/GetAddonsByBranch',
    method: 'get',
    params: data,
  })
}
export function AddBranchAddon(data) {
  return request({
    url: '/Addon/AddBranchAddon',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function GetAddonsByItem(data) {
  return request({
    url: '/Addon/GetAddonsByItem',
    method: 'get',
    params: data,
  })
}
export function GetItemAndAddonsByItem(data) {
  return request({
    url: '/Addon/GetItemAndAddonsByItem',
    method: 'get',
    params: data,
  })
}
