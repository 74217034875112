<template>
  <v-select
    @change="Setmodel"
    v-model="value"
    :value="value"
    :items="items"
    label="City"
    item-text="label"
    item-value="value"
    hide-details
    :prepend-icon="icons.mdiMap"
    single-line
  ></v-select>
</template>

<script>
import { mdiMap } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  model: {
    prop: 'City',
    event: 'update:city',
  },
  props: {
    City: {
      type: String,
    },
  },
  watch: {
    City(val) {
      this.value = val
    },
  },
  setup(props, { emit }) {
    const value = ref('')
    const Setmodel = () => {
      emit('update:city', value.value)
      emit('change', value.value)
    }
    const items = [
      {
        label: 'Amman',
        value: 'Amman',
      },
      {
        label: 'Zarqa',
        value: 'Zarqa',
      },
      {
        label: 'Ajloun',
        value: 'Ajloun',
      },
      {
        label: 'Aqaba',
        value: 'Aqaba',
      },
      {
        label: 'Balqa',
        value: 'Balqa',
      },
      {
        label: 'Irbid',
        value: 'Irbid',
      },
      {
        label: 'Jerash',
        value: 'Jerash',
      },
      {
        label: 'Karak',
        value: 'Karak',
      },
      {
        label: "Ma'an",
        value: "Ma'an",
      },
      {
        label: 'Madaba',
        value: 'Madaba',
      },
      {
        label: 'Mafraq',
        value: 'Mafraq',
      },
      {
        label: 'Tafilah',
        value: 'Tafilah',
      },
    ]

    return {
      items,
      Setmodel,
      value,
      icons: {
        mdiMap,
      },
    }
  },
}
</script>
