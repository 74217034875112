<template>
  <div>
    <div id="field_container">
      <v-text-field
        hide-details="auto"
        v-model="newValue"
        type="text"
        readonly
        :label="label"
        small
        :prepend-inner-icon="icons.mdiMinus"
        :append-icon="icons.mdiPlus"
        @click:append="mpplus()"
        @click:prepend-inner="mpminus()"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import { mdiMinus, mdiPlus } from '@mdi/js'

export default {
  props: {
    value: {
      default: 0,
      type: Number,
    },
    min: {
      default: 0,
      type: Number,
    },
    max: {
      default: undefined,
      type: Number,
    },
    label: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      newValue: 0,
      icons: {
        mdiPlus,
        mdiMinus,
      },
    }
  },

  methods: {
    getNotificationClass(notification) {
      return `alert alert-${notification.type}`
    },
    mpplus: function () {
      if (this.max === undefined || this.newValue < this.max) {
        this.newValue = this.newValue + 1
        this.$emit('input', this.newValue)
      }
    },
    mpminus: function () {
      if (this.newValue > this.min) {
        this.newValue = this.newValue - 1
        this.$emit('input', this.newValue)
      }
    },
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.newValue = newVal
      },
    },
  },
  created: function () {
    this.newValue = this.value
  },
}
</script>
<style lang="scss" scoped>
.minusplusnumber {
  border: 1px solid silver;
  border-radius: 5px;
  background-color: #fff;
  margin: 0 5px 0 5px;
  display: inline-block;
  user-select: none;
}
.minusplusnumber div {
  display: inline-block;
}
.minusplusnumber #field_container input {
  width: 50px;
  text-align: center;
  font-size: 15px;
  padding: 3px;
  border: none;
}
.minusplusnumber .mpbtn {
  padding: 3px 10px 3px 10px;
  cursor: pointer;
  border-radius: 5px;
}
.minusplusnumber .mpbtn:hover {
  background-color: #ddd;
}
.minusplusnumber .mpbtn:active {
  background-color: #c5c5c5;
}
</style>
