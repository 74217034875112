<template>
  <v-dialog style="height: 1200px" width="800px" :value="isActive" @input="val => $emit('update:is-active', val)">
    <v-card class="transition-fast-in-fast-out" style="height: 100%">
      <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
        <v-card-title>
          <span class="text-big cursor-pointer"> Complaint Order : {{ Order.Id }} </span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <span class="text-big cursor-pointer">Complain Category</span>

          <vc-complain-category-select :rules="[validators.required]" v-model="ComplainData.ComplainCategory" />
          <v-divider class="mt-3 mb-3"></v-divider>

          <v-textarea
            v-model="ComplainData.Comment"
            class="mx-2"
            :rules="[validators.required]"
            rows="1"
            hide-details="auto"
            outlined
            label="Comment"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="close"> Cancel </v-btn>
          <v-btn color="success" type="submit"> Save </v-btn>
        </v-card-actions></v-form
      >
    </v-card>
    <v-divider class="mt-6 mb-6"></v-divider>

    <v-data-table :headers="tableColumns" :items="Order.Complains" item-key="Id">
      <!-- CreationTime -->
      <template #[`item.CreationTime`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary font-weight-semibold text-truncate">{{
              filter.parseTime(item.CreationTime, '{y}-{m}-{d} {h}:{i}')
            }}</span>
          </div>
        </div>
      </template>
    </v-data-table>
  </v-dialog>
</template>

<script>
import VcComplainCategorySelect from '@/components/vc-select/VcComplainCategorySelect.vue'
import { Create } from '@core/api/Complain'
import { parseTime } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import { mdiCartPlus, mdiCheck, mdiCircle, mdiCircleOutline, mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: { VcComplainCategorySelect },

  model: {
    prop: 'isActive',
    event: 'update:is-active',
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    Order: {
      type: Object,
      required: true,
    },
    RestaurantId: { type: Number },
    Customer: { type: Object },
  },
  watch: {
    Order(val) {
      this.ComplainData.OrderId = val.Id
    },
  },
  setup(props, { emit }) {
    const blankData = {
      Id: undefined,
      ComplainCategory: '',
      Comment: '',
      AdminReply: '',
      OrderId: undefined,
      BranshId: undefined,
    }
    const tableColumns = [
      { text: 'id', value: 'Id', sortable: false },
      { text: 'Complain Category', value: 'ComplainCategory', sortable: false },
      { text: 'Comment', value: 'Comment', sortable: false },
      { text: 'Creation Time', value: 'CreationTime', sortable: false },
    ]
    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }
    const ComplainData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetComplainData = () => {
      ComplainData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-active', false)
    }
    const close = () => {
      resetComplainData()
    }
    const onSubmit = () => {
      if (valid.value) {
        console.log('vaild',valid)
        ComplainData.value.OrderId = props.Order.Id
        ComplainData.value.BranchId = props.Order.BranchId
        ComplainData.value.RestaurantId = props.RestaurantId
        ComplainData.value.PhoneNumber = props.Customer.PhoneNumber

        Create(ComplainData.value).then(() => {
          emit('refetch-data')
          emit('update:is-active', false)
        })
        resetComplainData()
      } else {
        validate()
      }
    }
    return {
      tableColumns,
      ComplainData,
      mdiPlus,
      onSubmit,
      close,
      validate,
      resetComplainData,
      form,
      validators: { required },
      valid,
      filter: { parseTime },
      icons: {
        mdiCircleOutline,
        mdiCircle,
        mdiClose,
        mdiClockTimeFourOutline,
        mdiCheck,
        mdiCartPlus,
      },
    }
  },
}
</script>
