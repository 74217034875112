<template>
  <v-dialog
    style="height: 1200px"
    width="800px"
    :value="isActive"
    @input="
      val => {
        $emit('update:is-active', val)
        resetOrderTransationData()
      }
    "
  >
    <v-card class="transition-fast-in-fast-out" style="height: 100%">
      <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
        <v-card-title>
          <span class="text-big cursor-pointer"> {{ Item.Name }} </span>
          <v-spacer></v-spacer>
          <span class="me-3"
            >Price :{{ OrderTransationData.TotalPrice == 0 ? 'Price On  Selection' : OrderTransationData.TotalPrice * OrderTransationData.Qty }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-row v-if="Item.Type == 1">
        <v-col class="pr-4">
          <v-slider
            v-model="OrderTransationData.TotalPrice"
            class="align-center"
            :max="max"
            :min="Item.Price"
            hide-details
            @change="setPrice(OrderTransationData.TotalPrice)"
          >
          </v-slider>
        </v-col>
      </v-row>
          <v-row>
            <v-col cols="10">
              <span class="text-big cursor-pointer">{{ Item.Description }}</span> </v-col
            ><v-col>
              <plus-minus-field
                :rules="[validators.minQtyValidator(OrderTransationData.Qty, 1)]"
                :max="100"
                :min="1"
                label="Qty"
                v-model="OrderTransationData.Qty"
              />
            </v-col>
          </v-row>
          <!-- Addons -->
          <v-expansion-panels v-if="Item.Addons.length > 0" accordion :value="Item.Addons.map((_k, i) => i)" multiple>
            <v-expansion-panel v-for="(Addon, i) in Item.Addons" :key="i" >
              <v-expansion-panel-header
                >{{ Addon.Name }} {{ Addon.IsRequired ? ' - Required *' : '' }}
                {{ Addon.IsDependence ? ' -  Required*' : '' }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>
                    <v-card class="d-flex flex-wrap"
                    flat
                    tile>
                     <v-radio-group
                          hide-details="auto"
                          v-model="OrderTransationData.Addon[i]"
                          v-if="Addon.IsMulti != true"
                          @change="CalPrice"
                          v-bind:rules="
                            Addon.IsDependence == true || Addon.IsRequired == true
                              ? [v => (v != undefined ? true : 'Addon is required')]
                              : []
                          "
                          row >
                          <v-radio
                          class="mr-5"
                            v-for="option in Addon.OptionAddons"
                            :key="option.Id"
                            :label="`${option.Name} (${option.Price})`"
                            :value="option"
                            :on-icon="icons.mdiCircle"
                            :off-icon="icons.mdiCircleOutline"
                            color="primary"
                          :disabled="option.Status == 'inactive'? true : false"
                          ></v-radio>
                        </v-radio-group>
                        <v-checkbox
                        class="mr-5"
                          hide-details
                          v-if="Addon.IsMulti == true"
                          @change="CalPrice"
                          multiple
                          v-model="OrderTransationData.Addon[i]"
                          v-bind:rules="Addon.IsRequired == true ? [validators.required] : []"
                          v-for="option in Addon.OptionAddons"
                          :key="option.Id"
                          :label="`${option.Name} (${option.Price})`"
                          :value="option"
                          :on-icon="icons.mdiCircle"
                          :off-icon="icons.mdiCircleOutline"
                          color="primary"
                        >
                        </v-checkbox>
                    </v-card>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row class="pt-2 pb-2 px-1">
            <v-col cols="12" md="125">
              <v-textarea
                class="mx-2"
                rows="1"
                hide-details="auto"
                v-model="OrderTransationData.Description"
                outlined
                label="Extra note .."
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row justify="space-around">
            <v-btn x-large class="pl-10 pr-10" color="warning" type="submit">
              <v-icon>{{ icons.mdiCartPlus }}</v-icon
              >Add to Cart</v-btn
            ></v-row
          >
        </v-card-text></v-form
      >
    </v-card>
  </v-dialog>
</template>
<script>
import PlusMinusField from '@/components/vc-input/PlusMinusField.vue'
import { minQtyValidator, required } from '@core/utils/validation'
import { mdiCartPlus, mdiCheck, mdiCircle, mdiCircleOutline, mdiClockTimeFourOutline, mdiClose } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

export default {
  components: { PlusMinusField },
  model: {
    prop: 'isActive',
    event: 'update:is-active',
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    Item: {
      type: Object,
      required: true,
    },
  },
   watch: {
     Item: {
      handler(val){
       this.OrderTransationData.ItemId = val.Id
       this.OrderTransationData.Name = val.Name
       this.OrderTransationData.Addon = []

       this.CalPrice()
       },
       deep: true
     },
   },
  setup(props, { emit }) {
    const blankData = {
      Id: undefined,
      Qty: 1,
      Name: '',
      TotalPrice: 0,
      Description: '',
      Addon: [],
      ItemId: undefined,
    }
    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }
    const OrderTransationData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetOrderTransationData = () => {
      OrderTransationData.value = JSON.parse(JSON.stringify(blankData))
      //resetForm()
      emit('update:is-active', false)
    }
    onMounted(() => {
      OrderTransationData.value.Addon = []
      console.log('OrderTransationData.value', OrderTransationData.value)
      CalPrice()
    })
    const CalPrice = () => {
      const addontotalprice = OrderTransationData.value.Addon.reduce((prev, cur) => {
        if (Array.isArray(cur) && cur.length > 0 && cur != null) {
          return (
            prev +
            cur.reduce((p, c) => {
              return p + (c.Price == undefined ? 0 : c.Price)
            }, 0)
          )
        }

        if (typeof cur == 'object' && Object.keys(cur).length != 0) {
          return prev + (cur.Price === undefined ? 0 : cur.Price)
        }
        return prev + 0
      }, 0)
      if ( props.Item.Type == 1){
        OrderTransationData.value.TotalPrice += + parseFloat(addontotalprice)
      }
      else {
      OrderTransationData.value.TotalPrice = parseFloat(props.Item.Price) + parseFloat(addontotalprice)
      }
    }
    const onSubmit = () => {
      if (valid.value) {
        const NewOrderTransationDataAddon = []
        OrderTransationData.value.Addon.map((x, indesx) => {
          if (Array.isArray(x) && x.length != 0) {
            x.map(v => {
              if (Object.keys(v).length != 0) NewOrderTransationDataAddon.push({ ...v })
            })
          } else if (x.length != 0 && typeof x == 'object' && Object.keys(x).length != 0) {
            //
            NewOrderTransationDataAddon.push({ ...x })
          }
        })
        OrderTransationData.value.Addon = [...NewOrderTransationDataAddon]
        OrderTransationData.value.ItemId = props.Item.Id
        let HaveIsDependence = NewOrderTransationDataAddon.findIndex(f => f.IsDependence == true)
        if (HaveIsDependence != -1) {
          OrderTransationData.value.Name = props.Item.Name + ' - ' + NewOrderTransationDataAddon[HaveIsDependence].Name
        } else OrderTransationData.value.Name = props.Item.Name

        emit('PushOrderTransationData',  OrderTransationData.value )
        // emit('update:is-active', false)

        resetOrderTransationData()
      } else {
        validate()
      }
    }
    const max = ref(100)
      const setPrice = (price) => {
        props.Item.Price == parseFloat(price)
      }

    return {
      setPrice,
      max,
      OrderTransationData,
      onSubmit,
      CalPrice,
      validate,
      resetOrderTransationData,
      form,
      validators: { required, minQtyValidator },
      valid,
      // validation
      icons: {
        mdiCircleOutline,
        mdiCircle,
        mdiClose,
        mdiClockTimeFourOutline,
        mdiCheck,
        mdiCartPlus,
      },
    }
  },
}
</script>
