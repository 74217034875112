<template>
  <v-select
  :rules="[validators.required]"
    @change="Setmodel"
    v-model="value"
    :value="value"
    :items="items"
    label="Complain Category"
    item-text="label"
    item-value="value"
    hide-details
    :prepend-icon="icons.mdiCommentFlash"
    single-line
  ></v-select>
</template>

<script>
import { required } from '@core/utils/validation'
import { mdiCommentFlash } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  model: {
    prop: 'ComplainCategory',
    event: 'update:complain-category',
  },
  props: {
    ComplainCategory: {
      type: String,
    },
  },
  watch: {
    ComplainCategory(val) {
      this.value = val
    },
  },
  setup(props, { emit }) {
    const value = ref('')
    const Setmodel = () => {
      emit('update:complain-category', value.value)
      emit('change', value.value)
    }
    const items = [
      {
        label: 'الزبون يشكي من الاكل',
        value: 'الزبون يشكي من الاكل',
      },
      {
        label: 'الزبون يشكي من موظف المطعم',
        value: 'الزبون يشكي من موظف المطعم',
      },
      {
        label: 'الزبون يشكي من موظف التوصيل',
        value: 'الزبون يشكي من موظف التوصيل',
      },
      {
        label: 'الزبون يشكي من موظف خدمة العملاء',
        value: 'الزبون يشكي من موظف خدمة العملاء',
      },
      {
        label: 'شكوى اخرى',
        value: 'شكوى اخرى',
      },
    ]

    return {
      items,
      Setmodel,
      value,
      validators: { required },
      icons: {
        mdiCommentFlash,
      },
    }
  },
}
</script>
